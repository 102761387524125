$spacer:1rem !default;
$sm:576px !default;
$md:768px !default;
$lg:992px !default;
$xl:1200px !default;

.p-mt-0 {margin-top:0 !important;}
.p-mt-1 {margin-top:$spacer * 0.25 !important;}
.p-mt-2 {margin-top:$spacer * 0.5 !important;}
.p-mt-3 {margin-top:$spacer * 1 !important;}
.p-mt-4 {margin-top:$spacer * 1.5 !important;}
.p-mt-5 {margin-top:$spacer * 2 !important;}
.p-mt-6 {margin-top:$spacer * 3 !important;}
.p-mt-auto {margin-top:auto !important;}

.p-mr-0 {margin-right:0 !important;}
.p-mr-1 {margin-right:$spacer * 0.25 !important;}
.p-mr-2 {margin-right:$spacer * 0.5 !important;}
.p-mr-3 {margin-right:$spacer * 1 !important;}
.p-mr-4 {margin-right:$spacer * 1.5 !important;}
.p-mr-5 {margin-right:$spacer * 2 !important;}
.p-mr-6 {margin-right:$spacer * 3 !important;}
.p-mr-auto {margin-right:auto !important;}

.p-ml-0 {margin-left:0 !important;}
.p-ml-1 {margin-left:$spacer * 0.25 !important;}
.p-ml-2 {margin-left:$spacer * 0.5 !important;}
.p-ml-3 {margin-left:$spacer * 1 !important;}
.p-ml-4 {margin-left:$spacer * 1.5 !important;}
.p-ml-5 {margin-left:$spacer * 2 !important;}
.p-ml-6 {margin-left:$spacer * 3 !important;}
.p-ml-auto {margin-left:auto !important;}

.p-mb-0 {margin-bottom:0 !important;}
.p-mb-1 {margin-bottom:$spacer * 0.25 !important;}
.p-mb-2 {margin-bottom:$spacer * 0.5 !important;}
.p-mb-3 {margin-bottom:$spacer * 1 !important;}
.p-mb-4 {margin-bottom:$spacer * 1.5 !important;}
.p-mb-5 {margin-bottom:$spacer * 2 !important;}
.p-mb-6 {margin-bottom:$spacer * 3 !important;}
.p-mb-auto {margin-bottom:auto !important;}

.p-mx-0 {margin-left:0 !important;margin-right:0 !important;}
.p-mx-1 {margin-left:$spacer * 0.25 !important;margin-right:$spacer * 0.25 !important;}
.p-mx-2 {margin-left:$spacer * 0.5 !important;margin-right:$spacer * 0.5 !important;}
.p-mx-3 {margin-left:$spacer * 1 !important;margin-right:$spacer * 1 !important;}
.p-mx-4 {margin-left:$spacer * 1.5 !important;margin-right:$spacer * 1.5 !important;}
.p-mx-5 {margin-left:$spacer * 2 !important;margin-right:$spacer * 2 !important;}
.p-mx-6 {margin-left:$spacer * 3 !important;margin-right:$spacer * 3 !important;}
.p-mx-auto {margin-left:auto !important;margin-right:auto !important;}

.p-my-0 {margin-top:0 !important;margin-bottom:0 !important;}
.p-my-1 {margin-top:$spacer * 0.25 !important;margin-bottom:$spacer * 0.25 !important;}
.p-my-2 {margin-top:$spacer * 0.5 !important;margin-bottom:$spacer * 0.5 !important;}
.p-my-3 {margin-top:$spacer * 1 !important;margin-bottom:$spacer * 1 !important;}
.p-my-4 {margin-top:$spacer * 1.5 !important;margin-bottom:$spacer * 1.5 !important;}
.p-my-5 {margin-top:$spacer * 2 !important;margin-bottom:$spacer * 2 !important;}
.p-my-6 {margin-top:$spacer * 3 !important;margin-bottom:$spacer * 3 !important;}
.p-my-auto {margin-top:auto !important;margin-bottom:auto !important;}

.p-m-0 {margin:0 !important;}
.p-m-1 {margin:$spacer * 0.25 !important;}
.p-m-2 {margin:$spacer * 0.5 !important;}
.p-m-3 {margin:$spacer * 1 !important;}
.p-m-4 {margin:$spacer * 1.5 !important;}
.p-m-5 {margin:$spacer * 2 !important;}
.p-m-6 {margin:$spacer * 3 !important;}
.p-m-auto {margin:auto !important;}

@media screen and (min-width: $sm) {
    .p-mt-sm-0 {margin-top:0 !important;}
    .p-mt-sm-1 {margin-top:$spacer * 0.25 !important;}
    .p-mt-sm-2 {margin-top:$spacer * 0.5 !important;}
    .p-mt-sm-3 {margin-top:$spacer * 1 !important;}
    .p-mt-sm-4 {margin-top:$spacer * 1.5 !important;}
    .p-mt-sm-5 {margin-top:$spacer * 2 !important;}
    .p-mt-sm-6 {margin-top:$spacer * 3 !important;}
    .p-mt-sm-auto {margin-top:$spacer * 3 !important;}

    .p-mr-sm-0 {margin-right:0 !important;}
    .p-mr-sm-1 {margin-right:$spacer * 0.25 !important;}
    .p-mr-sm-2 {margin-right:$spacer * 0.5 !important;}
    .p-mr-sm-3 {margin-right:$spacer * 1 !important;}
    .p-mr-sm-4 {margin-right:$spacer * 1.5 !important;}
    .p-mr-sm-5 {margin-right:$spacer * 2 !important;}
    .p-mr-sm-6 {margin-right:$spacer * 3 !important;}
    .p-mr-sm-auto {margin-right:auto !important;}

    .p-ml-sm-0 {margin-left:0 !important;}
    .p-ml-sm-1 {margin-left:$spacer * 0.25 !important;}
    .p-ml-sm-2 {margin-left:$spacer * 0.5 !important;}
    .p-ml-sm-3 {margin-left:$spacer * 1 !important;}
    .p-ml-sm-4 {margin-left:$spacer * 1.5 !important;}
    .p-ml-sm-5 {margin-left:$spacer * 2 !important;}
    .p-ml-sm-6 {margin-left:$spacer * 3 !important;}
    .p-ml-sm-auto {margin-left:auto !important;}

    .p-mb-sm-0 {margin-bottom:0 !important;}
    .p-mb-sm-1 {margin-bottom:$spacer * 0.25 !important;}
    .p-mb-sm-2 {margin-bottom:$spacer * 0.5 !important;}
    .p-mb-sm-3 {margin-bottom:$spacer * 1 !important;}
    .p-mb-sm-4 {margin-bottom:$spacer * 1.5 !important;}
    .p-mb-sm-5 {margin-bottom:$spacer * 2 !important;}
    .p-mb-sm-6 {margin-bottom:$spacer * 3 !important;}
    .p-mb-sm-auto {margin-bottom:auto !important;}

    .p-mx-sm-0 {margin-left:0 !important;margin-right:0 !important;}
    .p-mx-sm-1 {margin-left:$spacer * 0.25 !important;margin-right:$spacer * 0.25 !important;}
    .p-mx-sm-2 {margin-left:$spacer * 0.5 !important;margin-right:$spacer * 0.5 !important;}
    .p-mx-sm-3 {margin-left:$spacer * 1 !important;margin-right:$spacer * 1 !important;}
    .p-mx-sm-4 {margin-left:$spacer * 1.5 !important;margin-right:$spacer * 1.5 !important;}
    .p-mx-sm-5 {margin-left:$spacer * 2 !important;margin-right:$spacer * 2 !important;}
    .p-mx-sm-6 {margin-left:$spacer * 3 !important;margin-right:$spacer * 3 !important;}
    .p-mx-sm-auto {margin-left:auto !important;margin-right: auto !important;}

    .p-my-sm-0 {margin-top:0 !important;margin-bottom:0 !important;}
    .p-my-sm-1 {margin-top:$spacer * 0.25 !important;margin-bottom:$spacer * 0.25 !important;}
    .p-my-sm-2 {margin-top:$spacer * 0.5 !important;margin-bottom:$spacer * 0.5 !important;}
    .p-my-sm-3 {margin-top:$spacer * 1 !important;margin-bottom:$spacer * 1 !important;}
    .p-my-sm-4 {margin-top:$spacer * 1.5 !important;margin-bottom:$spacer * 1.5 !important;}
    .p-my-sm-5 {margin-top:$spacer * 2 !important;margin-bottom:$spacer * 2 !important;}
    .p-my-sm-6 {margin-top:$spacer * 3 !important;margin-bottom:$spacer * 3 !important;}
    .p-my-sm-auto {margin-top:auto !important;margin-bottom:auto !important;}

    .p-m-sm-0 {margin:0 !important;}
    .p-m-sm-1 {margin:$spacer * 0.25 !important;}
    .p-m-sm-2 {margin:$spacer * 0.5 !important;}
    .p-m-sm-3 {margin:$spacer * 1 !important;}
    .p-m-sm-4 {margin:$spacer * 1.5 !important;}
    .p-m-sm-5 {margin:$spacer * 2 !important;}
    .p-m-sm-6 {margin:$spacer * 3 !important;}
    .p-m-sm-auto {margin:auto !important;}
}

@media screen and (min-width: $md) {
    .p-mt-md-0 {margin-top:0 !important;}
    .p-mt-md-1 {margin-top:$spacer * 0.25 !important;}
    .p-mt-md-2 {margin-top:$spacer * 0.5 !important;}
    .p-mt-md-3 {margin-top:$spacer * 1 !important;}
    .p-mt-md-4 {margin-top:$spacer * 1.5 !important;}
    .p-mt-md-5 {margin-top:$spacer * 2 !important;}
    .p-mt-md-6 {margin-top:$spacer * 3 !important;}
    .p-mt-md-auto {margin-top:$spacer * 3 !important;}

    .p-mr-md-0 {margin-right:0 !important;}
    .p-mr-md-1 {margin-right:$spacer * 0.25 !important;}
    .p-mr-md-2 {margin-right:$spacer * 0.5 !important;}
    .p-mr-md-3 {margin-right:$spacer * 1 !important;}
    .p-mr-md-4 {margin-right:$spacer * 1.5 !important;}
    .p-mr-md-5 {margin-right:$spacer * 2 !important;}
    .p-mr-md-6 {margin-right:$spacer * 3 !important;}
    .p-mr-md-auto {margin-right:auto !important;}

    .p-ml-md-0 {margin-left:0 !important;}
    .p-ml-md-1 {margin-left:$spacer * 0.25 !important;}
    .p-ml-md-2 {margin-left:$spacer * 0.5 !important;}
    .p-ml-md-3 {margin-left:$spacer * 1 !important;}
    .p-ml-md-4 {margin-left:$spacer * 1.5 !important;}
    .p-ml-md-5 {margin-left:$spacer * 2 !important;}
    .p-ml-md-6 {margin-left:$spacer * 3 !important;}
    .p-ml-md-auto {margin-left:auto !important;}

    .p-mb-md-0 {margin-bottom:0 !important;}
    .p-mb-md-1 {margin-bottom:$spacer * 0.25 !important;}
    .p-mb-md-2 {margin-bottom:$spacer * 0.5 !important;}
    .p-mb-md-3 {margin-bottom:$spacer * 1 !important;}
    .p-mb-md-4 {margin-bottom:$spacer * 1.5 !important;}
    .p-mb-md-5 {margin-bottom:$spacer * 2 !important;}
    .p-mb-md-6 {margin-bottom:$spacer * 3 !important;}
    .p-mb-md-auto {margin-bottom:auto !important;}

    .p-mx-md-0 {margin-left:0 !important;margin-right:0 !important;}
    .p-mx-md-1 {margin-left:$spacer * 0.25 !important;margin-right:$spacer * 0.25 !important;}
    .p-mx-md-2 {margin-left:$spacer * 0.5 !important;margin-right:$spacer * 0.5 !important;}
    .p-mx-md-3 {margin-left:$spacer * 1 !important;margin-right:$spacer * 1 !important;}
    .p-mx-md-4 {margin-left:$spacer * 1.5 !important;margin-right:$spacer * 1.5 !important;}
    .p-mx-md-5 {margin-left:$spacer * 2 !important;margin-right:$spacer * 2 !important;}
    .p-mx-md-6 {margin-left:$spacer * 3 !important;margin-right:$spacer * 3 !important;}
    .p-mx-md-auto {margin-left:auto !important;margin-right: auto !important;}

    .p-my-md-0 {margin-top:0 !important;margin-bottom:0 !important;}
    .p-my-md-1 {margin-top:$spacer * 0.25 !important;margin-bottom:$spacer * 0.25 !important;}
    .p-my-md-2 {margin-top:$spacer * 0.5 !important;margin-bottom:$spacer * 0.5 !important;}
    .p-my-md-3 {margin-top:$spacer * 1 !important;margin-bottom:$spacer * 1 !important;}
    .p-my-md-4 {margin-top:$spacer * 1.5 !important;margin-bottom:$spacer * 1.5 !important;}
    .p-my-md-5 {margin-top:$spacer * 2 !important;margin-bottom:$spacer * 2 !important;}
    .p-my-md-6 {margin-top:$spacer * 3 !important;margin-bottom:$spacer * 3 !important;}
    .p-my-md-auto {margin-top:auto !important;margin-bottom:auto !important;}

    .p-m-md-0 {margin:0 !important;}
    .p-m-md-1 {margin:$spacer * 0.25 !important;}
    .p-m-md-2 {margin:$spacer * 0.5 !important;}
    .p-m-md-3 {margin:$spacer * 1 !important;}
    .p-m-md-4 {margin:$spacer * 1.5 !important;}
    .p-m-md-5 {margin:$spacer * 2 !important;}
    .p-m-md-6 {margin:$spacer * 3 !important;}
    .p-m-md-auto {margin:auto !important;}
}

@media screen and (min-width: $lg) {
    .p-mt-lg-0 {margin-top:0 !important;}
    .p-mt-lg-1 {margin-top:$spacer * 0.25 !important;}
    .p-mt-lg-2 {margin-top:$spacer * 0.5 !important;}
    .p-mt-lg-3 {margin-top:$spacer * 1 !important;}
    .p-mt-lg-4 {margin-top:$spacer * 1.5 !important;}
    .p-mt-lg-5 {margin-top:$spacer * 2 !important;}
    .p-mt-lg-6 {margin-top:$spacer * 3 !important;}
    .p-mt-lg-auto {margin-top:$spacer * 3 !important;}

    .p-mr-lg-0 {margin-right:0 !important;}
    .p-mr-lg-1 {margin-right:$spacer * 0.25 !important;}
    .p-mr-lg-2 {margin-right:$spacer * 0.5 !important;}
    .p-mr-lg-3 {margin-right:$spacer * 1 !important;}
    .p-mr-lg-4 {margin-right:$spacer * 1.5 !important;}
    .p-mr-lg-5 {margin-right:$spacer * 2 !important;}
    .p-mr-lg-6 {margin-right:$spacer * 3 !important;}
    .p-mr-lg-auto {margin-right:auto !important;}

    .p-ml-lg-0 {margin-left:0 !important;}
    .p-ml-lg-1 {margin-left:$spacer * 0.25 !important;}
    .p-ml-lg-2 {margin-left:$spacer * 0.5 !important;}
    .p-ml-lg-3 {margin-left:$spacer * 1 !important;}
    .p-ml-lg-4 {margin-left:$spacer * 1.5 !important;}
    .p-ml-lg-5 {margin-left:$spacer * 2 !important;}
    .p-ml-lg-6 {margin-left:$spacer * 3 !important;}
    .p-ml-lg-auto {margin-left:auto !important;}

    .p-mb-lg-0 {margin-bottom:0 !important;}
    .p-mb-lg-1 {margin-bottom:$spacer * 0.25 !important;}
    .p-mb-lg-2 {margin-bottom:$spacer * 0.5 !important;}
    .p-mb-lg-3 {margin-bottom:$spacer * 1 !important;}
    .p-mb-lg-4 {margin-bottom:$spacer * 1.5 !important;}
    .p-mb-lg-5 {margin-bottom:$spacer * 2 !important;}
    .p-mb-lg-6 {margin-bottom:$spacer * 3 !important;}
    .p-mb-lg-auto {margin-bottom:auto !important;}

    .p-mx-lg-0 {margin-left:0 !important;margin-right:0 !important;}
    .p-mx-lg-1 {margin-left:$spacer * 0.25 !important;margin-right:$spacer * 0.25 !important;}
    .p-mx-lg-2 {margin-left:$spacer * 0.5 !important;margin-right:$spacer * 0.5 !important;}
    .p-mx-lg-3 {margin-left:$spacer * 1 !important;margin-right:$spacer * 1 !important;}
    .p-mx-lg-4 {margin-left:$spacer * 1.5 !important;margin-right:$spacer * 1.5 !important;}
    .p-mx-lg-5 {margin-left:$spacer * 2 !important;margin-right:$spacer * 2 !important;}
    .p-mx-lg-6 {margin-left:$spacer * 3 !important;margin-right:$spacer * 3 !important;}
    .p-mx-lg-auto {margin-left:auto !important;margin-right: auto !important;}

    .p-my-lg-0 {margin-top:0 !important;margin-bottom:0 !important;}
    .p-my-lg-1 {margin-top:$spacer * 0.25 !important;margin-bottom:$spacer * 0.25 !important;}
    .p-my-lg-2 {margin-top:$spacer * 0.5 !important;margin-bottom:$spacer * 0.5 !important;}
    .p-my-lg-3 {margin-top:$spacer * 1 !important;margin-bottom:$spacer * 1 !important;}
    .p-my-lg-4 {margin-top:$spacer * 1.5 !important;margin-bottom:$spacer * 1.5 !important;}
    .p-my-lg-5 {margin-top:$spacer * 2 !important;margin-bottom:$spacer * 2 !important;}
    .p-my-lg-6 {margin-top:$spacer * 3 !important;margin-bottom:$spacer * 3 !important;}
    .p-my-lg-auto {margin-top:auto !important;margin-bottom:auto !important;}

    .p-m-lg-0 {margin:0 !important;}
    .p-m-lg-1 {margin:$spacer * 0.25 !important;}
    .p-m-lg-2 {margin:$spacer * 0.5 !important;}
    .p-m-lg-3 {margin:$spacer * 1 !important;}
    .p-m-lg-4 {margin:$spacer * 1.5 !important;}
    .p-m-lg-5 {margin:$spacer * 2 !important;}
    .p-m-lg-6 {margin:$spacer * 3 !important;}
    .p-m-lg-auto {margin:auto !important;}
}

@media screen and (min-width: $xl) {
    .p-mt-xl-0 {margin-top:0 !important;}
    .p-mt-xl-1 {margin-top:$spacer * 0.25 !important;}
    .p-mt-xl-2 {margin-top:$spacer * 0.5 !important;}
    .p-mt-xl-3 {margin-top:$spacer * 1 !important;}
    .p-mt-xl-4 {margin-top:$spacer * 1.5 !important;}
    .p-mt-xl-5 {margin-top:$spacer * 2 !important;}
    .p-mt-xl-6 {margin-top:$spacer * 3 !important;}
    .p-mt-xl-auto {margin-top:$spacer * 3 !important;}

    .p-mr-xl-0 {margin-right:0 !important;}
    .p-mr-xl-1 {margin-right:$spacer * 0.25 !important;}
    .p-mr-xl-2 {margin-right:$spacer * 0.5 !important;}
    .p-mr-xl-3 {margin-right:$spacer * 1 !important;}
    .p-mr-xl-4 {margin-right:$spacer * 1.5 !important;}
    .p-mr-xl-5 {margin-right:$spacer * 2 !important;}
    .p-mr-xl-6 {margin-right:$spacer * 3 !important;}
    .p-mr-xl-auto {margin-right:auto !important;}

    .p-ml-xl-0 {margin-left:0 !important;}
    .p-ml-xl-1 {margin-left:$spacer * 0.25 !important;}
    .p-ml-xl-2 {margin-left:$spacer * 0.5 !important;}
    .p-ml-xl-3 {margin-left:$spacer * 1 !important;}
    .p-ml-xl-4 {margin-left:$spacer * 1.5 !important;}
    .p-ml-xl-5 {margin-left:$spacer * 2 !important;}
    .p-ml-xl-6 {margin-left:$spacer * 3 !important;}
    .p-ml-xl-auto {margin-left:auto !important;}

    .p-mb-xl-0 {margin-bottom:0 !important;}
    .p-mb-xl-1 {margin-bottom:$spacer * 0.25 !important;}
    .p-mb-xl-2 {margin-bottom:$spacer * 0.5 !important;}
    .p-mb-xl-3 {margin-bottom:$spacer * 1 !important;}
    .p-mb-xl-4 {margin-bottom:$spacer * 1.5 !important;}
    .p-mb-xl-5 {margin-bottom:$spacer * 2 !important;}
    .p-mb-xl-6 {margin-bottom:$spacer * 3 !important;}
    .p-mb-xl-auto {margin-bottom:auto !important;}

    .p-mx-xl-0 {margin-left:0 !important;margin-right:0 !important;}
    .p-mx-xl-1 {margin-left:$spacer * 0.25 !important;margin-right:$spacer * 0.25 !important;}
    .p-mx-xl-2 {margin-left:$spacer * 0.5 !important;margin-right:$spacer * 0.5 !important;}
    .p-mx-xl-3 {margin-left:$spacer * 1 !important;margin-right:$spacer * 1 !important;}
    .p-mx-xl-4 {margin-left:$spacer * 1.5 !important;margin-right:$spacer * 1.5 !important;}
    .p-mx-xl-5 {margin-left:$spacer * 2 !important;margin-right:$spacer * 2 !important;}
    .p-mx-xl-6 {margin-left:$spacer * 3 !important;margin-right:$spacer * 3 !important;}
    .p-mx-xl-auto {margin-left:auto !important;margin-right: auto !important;}

    .p-my-xl-0 {margin-top:0 !important;margin-bottom:0 !important;}
    .p-my-xl-1 {margin-top:$spacer * 0.25 !important;margin-bottom:$spacer * 0.25 !important;}
    .p-my-xl-2 {margin-top:$spacer * 0.5 !important;margin-bottom:$spacer * 0.5 !important;}
    .p-my-xl-3 {margin-top:$spacer * 1 !important;margin-bottom:$spacer * 1 !important;}
    .p-my-xl-4 {margin-top:$spacer * 1.5 !important;margin-bottom:$spacer * 1.5 !important;}
    .p-my-xl-5 {margin-top:$spacer * 2 !important;margin-bottom:$spacer * 2 !important;}
    .p-my-xl-6 {margin-top:$spacer * 3 !important;margin-bottom:$spacer * 3 !important;}
    .p-my-xl-auto {margin-top:auto !important;margin-bottom:auto !important;}

    .p-m-xl-0 {margin:0 !important;}
    .p-m-xl-1 {margin:$spacer * 0.25 !important;}
    .p-m-xl-2 {margin:$spacer * 0.5 !important;}
    .p-m-xl-3 {margin:$spacer * 1 !important;}
    .p-m-xl-4 {margin:$spacer * 1.5 !important;}
    .p-m-xl-5 {margin:$spacer * 2 !important;}
    .p-m-xl-6 {margin:$spacer * 3 !important;}
    .p-m-xl-auto {margin:auto !important;}
}

.p-pt-0 {padding-top:0 !important;}
.p-pt-1 {padding-top:$spacer * 0.25 !important;}
.p-pt-2 {padding-top:$spacer * 0.5 !important;}
.p-pt-3 {padding-top:$spacer * 1 !important;}
.p-pt-4 {padding-top:$spacer * 1.5 !important;}
.p-pt-5 {padding-top:$spacer * 2 !important;}
.p-pt-6 {padding-top:$spacer * 3 !important;}

.p-pr-0 {padding-right:0 !important;}
.p-pr-1 {padding-right:$spacer * 0.25 !important;}
.p-pr-2 {padding-right:$spacer * 0.5 !important;}
.p-pr-3 {padding-right:$spacer * 1 !important;}
.p-pr-4 {padding-right:$spacer * 1.5 !important;}
.p-pr-5 {padding-right:$spacer * 2 !important;}
.p-pr-6 {padding-right:$spacer * 3 !important;}

.p-pl-0 {padding-left:0 !important;}
.p-pl-1 {padding-left:$spacer * 0.25 !important;}
.p-pl-2 {padding-left:$spacer * 0.5 !important;}
.p-pl-3 {padding-left:$spacer * 1 !important;}
.p-pl-4 {padding-left:$spacer * 1.5 !important;}
.p-pl-5 {padding-left:$spacer * 2 !important;}
.p-pl-6 {padding-left:$spacer * 3 !important;}

.p-pb-0 {padding-bottom:0 !important;}
.p-pb-1 {padding-bottom:$spacer * 0.25 !important;}
.p-pb-2 {padding-bottom:$spacer * 0.5 !important;}
.p-pb-3 {padding-bottom:$spacer * 1 !important;}
.p-pb-4 {padding-bottom:$spacer * 1.5 !important;}
.p-pb-5 {padding-bottom:$spacer * 2 !important;}
.p-pb-6 {padding-bottom:$spacer * 3 !important;}

.p-px-0 {padding-left:0 !important;padding-right:0 !important;}
.p-px-1 {padding-left:$spacer * 0.25 !important;padding-right:$spacer * 0.25 !important;}
.p-px-2 {padding-left:$spacer * 0.5 !important;padding-right:$spacer * 0.5 !important;}
.p-px-3 {padding-left:$spacer * 1 !important;padding-right:$spacer * 1 !important;}
.p-px-4 {padding-left:$spacer * 1.5 !important;padding-right:$spacer * 1.5 !important;}
.p-px-5 {padding-left:$spacer * 2 !important;padding-right:$spacer * 2 !important;}
.p-px-6 {padding-left:$spacer * 3 !important;padding-right:$spacer * 3 !important;}

.p-py-0 {padding-top:0 !important;padding-bottom:0 !important;}
.p-py-1 {padding-top:$spacer * 0.25 !important;padding-bottom:$spacer * 0.25 !important;}
.p-py-2 {padding-top:$spacer * 0.5 !important;padding-bottom:$spacer * 0.5 !important;}
.p-py-3 {padding-top:$spacer * 1 !important;padding-bottom:$spacer * 1 !important;}
.p-py-4 {padding-top:$spacer * 1.5 !important;padding-bottom:$spacer * 1.5 !important;}
.p-py-5 {padding-top:$spacer * 2 !important;padding-bottom:$spacer * 2 !important;}
.p-py-6 {padding-top:$spacer * 3 !important;padding-bottom:$spacer * 3 !important;}

.p-p-0 {padding:0 !important;}
.p-p-1 {padding:$spacer * 0.25 !important;}
.p-p-2 {padding:$spacer * 0.5 !important;}
.p-p-3 {padding:$spacer * 1 !important;}
.p-p-4 {padding:$spacer * 1.5 !important;}
.p-p-5 {padding:$spacer * 2 !important;}
.p-p-6 {padding:$spacer * 3 !important;}

@media screen and (min-width: $sm) {
    .p-pt-sm-0 {padding-top:0 !important;}
    .p-pt-sm-1 {padding-top:$spacer * 0.25 !important;}
    .p-pt-sm-2 {padding-top:$spacer * 0.5 !important;}
    .p-pt-sm-3 {padding-top:$spacer * 1 !important;}
    .p-pt-sm-4 {padding-top:$spacer * 1.5 !important;}
    .p-pt-sm-5 {padding-top:$spacer * 2 !important;}
    .p-pt-sm-6 {padding-top:$spacer * 3 !important;}

    .p-pr-sm-0 {padding-right:0 !important;}
    .p-pr-sm-1 {padding-right:$spacer * 0.25 !important;}
    .p-pr-sm-2 {padding-right:$spacer * 0.5 !important;}
    .p-pr-sm-3 {padding-right:$spacer * 1 !important;}
    .p-pr-sm-4 {padding-right:$spacer * 1.5 !important;}
    .p-pr-sm-5 {padding-right:$spacer * 2 !important;}
    .p-pr-sm-6 {padding-right:$spacer * 3 !important;}

    .p-pl-sm-0 {padding-left:0 !important;}
    .p-pl-sm-1 {padding-left:$spacer * 0.25 !important;}
    .p-pl-sm-2 {padding-left:$spacer * 0.5 !important;}
    .p-pl-sm-3 {padding-left:$spacer * 1 !important;}
    .p-pl-sm-4 {padding-left:$spacer * 1.5 !important;}
    .p-pl-sm-5 {padding-left:$spacer * 2 !important;}
    .p-pl-sm-6 {padding-left:$spacer * 3 !important;}

    .p-pb-sm-0 {padding-bottom:0 !important;}
    .p-pb-sm-1 {padding-bottom:$spacer * 0.25 !important;}
    .p-pb-sm-2 {padding-bottom:$spacer * 0.5 !important;}
    .p-pb-sm-3 {padding-bottom:$spacer * 1 !important;}
    .p-pb-sm-4 {padding-bottom:$spacer * 1.5 !important;}
    .p-pb-sm-5 {padding-bottom:$spacer * 2 !important;}
    .p-pb-sm-6 {padding-bottom:$spacer * 3 !important;}

    .p-px-sm-0 {padding-left:0 !important;padding-right:0 !important;}
    .p-px-sm-1 {padding-left:$spacer * 0.25 !important;padding-right:$spacer * 0.25 !important;}
    .p-px-sm-2 {padding-left:$spacer * 0.5 !important;padding-right:$spacer * 0.5 !important;}
    .p-px-sm-3 {padding-left:$spacer * 1 !important;padding-right:$spacer * 1 !important;}
    .p-px-sm-4 {padding-left:$spacer * 1.5 !important;padding-right:$spacer * 1.5 !important;}
    .p-px-sm-5 {padding-left:$spacer * 2 !important;padding-right:$spacer * 2 !important;}
    .p-px-sm-6 {padding-left:$spacer * 3 !important;padding-right:$spacer * 3 !important;}

    .p-py-sm-0 {padding-top:0 !important;padding-bottom:0 !important;}
    .p-py-sm-1 {padding-top:$spacer * 0.25 !important;padding-bottom:$spacer * 0.25 !important;}
    .p-py-sm-2 {padding-top:$spacer * 0.5 !important;padding-bottom:$spacer * 0.5 !important;}
    .p-py-sm-3 {padding-top:$spacer * 1 !important;padding-bottom:$spacer * 1 !important;}
    .p-py-sm-4 {padding-top:$spacer * 1.5 !important;padding-bottom:$spacer * 1.5 !important;}
    .p-py-sm-5 {padding-top:$spacer * 2 !important;padding-bottom:$spacer * 2 !important;}
    .p-py-sm-6 {padding-top:$spacer * 3 !important;padding-bottom:$spacer * 3 !important;}

    .p-p-sm-0 {padding:0 !important;}
    .p-p-sm-1 {padding:$spacer * 0.25 !important;}
    .p-p-sm-2 {padding:$spacer * 0.5 !important;}
    .p-p-sm-3 {padding:$spacer * 1 !important;}
    .p-p-sm-4 {padding:$spacer * 1.5 !important;}
    .p-p-sm-5 {padding:$spacer * 2 !important;}
    .p-p-sm-6 {padding:$spacer * 3 !important;}
}

@media screen and (min-width: $md) {
    .p-pt-md-0 {padding-top:0 !important;}
    .p-pt-md-1 {padding-top:$spacer * 0.25 !important;}
    .p-pt-md-2 {padding-top:$spacer * 0.5 !important;}
    .p-pt-md-3 {padding-top:$spacer * 1 !important;}
    .p-pt-md-4 {padding-top:$spacer * 1.5 !important;}
    .p-pt-md-5 {padding-top:$spacer * 2 !important;}
    .p-pt-md-6 {padding-top:$spacer * 3 !important;}

    .p-pr-md-0 {padding-right:0 !important;}
    .p-pr-md-1 {padding-right:$spacer * 0.25 !important;}
    .p-pr-md-2 {padding-right:$spacer * 0.5 !important;}
    .p-pr-md-3 {padding-right:$spacer * 1 !important;}
    .p-pr-md-4 {padding-right:$spacer * 1.5 !important;}
    .p-pr-md-5 {padding-right:$spacer * 2 !important;}
    .p-pr-md-6 {padding-right:$spacer * 3 !important;}

    .p-pl-md-0 {padding-left:0 !important;}
    .p-pl-md-1 {padding-left:$spacer * 0.25 !important;}
    .p-pl-md-2 {padding-left:$spacer * 0.5 !important;}
    .p-pl-md-3 {padding-left:$spacer * 1 !important;}
    .p-pl-md-4 {padding-left:$spacer * 1.5 !important;}
    .p-pl-md-5 {padding-left:$spacer * 2 !important;}
    .p-pl-md-6 {padding-left:$spacer * 3 !important;}

    .p-pb-md-0 {padding-bottom:0 !important;}
    .p-pb-md-1 {padding-bottom:$spacer * 0.25 !important;}
    .p-pb-md-2 {padding-bottom:$spacer * 0.5 !important;}
    .p-pb-md-3 {padding-bottom:$spacer * 1 !important;}
    .p-pb-md-4 {padding-bottom:$spacer * 1.5 !important;}
    .p-pb-md-5 {padding-bottom:$spacer * 2 !important;}
    .p-pb-md-6 {padding-bottom:$spacer * 3 !important;}

    .p-px-md-0 {padding-left:0 !important;padding-right:0 !important;}
    .p-px-md-1 {padding-left:$spacer * 0.25 !important;padding-right:$spacer * 0.25 !important;}
    .p-px-md-2 {padding-left:$spacer * 0.5 !important;padding-right:$spacer * 0.5 !important;}
    .p-px-md-3 {padding-left:$spacer * 1 !important;padding-right:$spacer * 1 !important;}
    .p-px-md-4 {padding-left:$spacer * 1.5 !important;padding-right:$spacer * 1.5 !important;}
    .p-px-md-5 {padding-left:$spacer * 2 !important;padding-right:$spacer * 2 !important;}
    .p-px-md-6 {padding-left:$spacer * 3 !important;padding-right:$spacer * 3 !important;}

    .p-py-md-0 {padding-top:0 !important;padding-bottom:0 !important;}
    .p-py-md-1 {padding-top:$spacer * 0.25 !important;padding-bottom:$spacer * 0.25 !important;}
    .p-py-md-2 {padding-top:$spacer * 0.5 !important;padding-bottom:$spacer * 0.5 !important;}
    .p-py-md-3 {padding-top:$spacer * 1 !important;padding-bottom:$spacer * 1 !important;}
    .p-py-md-4 {padding-top:$spacer * 1.5 !important;padding-bottom:$spacer * 1.5 !important;}
    .p-py-md-5 {padding-top:$spacer * 2 !important;padding-bottom:$spacer * 2 !important;}
    .p-py-md-6 {padding-top:$spacer * 3 !important;padding-bottom:$spacer * 3 !important;}

    .p-p-md-0 {padding:0 !important;}
    .p-p-md-1 {padding:$spacer * 0.25 !important;}
    .p-p-md-2 {padding:$spacer * 0.5 !important;}
    .p-p-md-3 {padding:$spacer * 1 !important;}
    .p-p-md-4 {padding:$spacer * 1.5 !important;}
    .p-p-md-5 {padding:$spacer * 2 !important;}
    .p-p-md-6 {padding:$spacer * 3 !important;}
}

@media screen and (min-width: $lg) {
    .p-pt-lg-0 {padding-top:0 !important;}
    .p-pt-lg-1 {padding-top:$spacer * 0.25 !important;}
    .p-pt-lg-2 {padding-top:$spacer * 0.5 !important;}
    .p-pt-lg-3 {padding-top:$spacer * 1 !important;}
    .p-pt-lg-4 {padding-top:$spacer * 1.5 !important;}
    .p-pt-lg-5 {padding-top:$spacer * 2 !important;}
    .p-pt-lg-6 {padding-top:$spacer * 3 !important;}
    .p-pt-lg-auto {padding-top:$spacer * 3 !important;}

    .p-pr-lg-0 {padding-right:0 !important;}
    .p-pr-lg-1 {padding-right:$spacer * 0.25 !important;}
    .p-pr-lg-2 {padding-right:$spacer * 0.5 !important;}
    .p-pr-lg-3 {padding-right:$spacer * 1 !important;}
    .p-pr-lg-4 {padding-right:$spacer * 1.5 !important;}
    .p-pr-lg-5 {padding-right:$spacer * 2 !important;}
    .p-pr-lg-6 {padding-right:$spacer * 3 !important;}

    .p-pl-lg-0 {padding-left:0 !important;}
    .p-pl-lg-1 {padding-left:$spacer * 0.25 !important;}
    .p-pl-lg-2 {padding-left:$spacer * 0.5 !important;}
    .p-pl-lg-3 {padding-left:$spacer * 1 !important;}
    .p-pl-lg-4 {padding-left:$spacer * 1.5 !important;}
    .p-pl-lg-5 {padding-left:$spacer * 2 !important;}
    .p-pl-lg-6 {padding-left:$spacer * 3 !important;}

    .p-pb-lg-0 {padding-bottom:0 !important;}
    .p-pb-lg-1 {padding-bottom:$spacer * 0.25 !important;}
    .p-pb-lg-2 {padding-bottom:$spacer * 0.5 !important;}
    .p-pb-lg-3 {padding-bottom:$spacer * 1 !important;}
    .p-pb-lg-4 {padding-bottom:$spacer * 1.5 !important;}
    .p-pb-lg-5 {padding-bottom:$spacer * 2 !important;}
    .p-pb-lg-6 {padding-bottom:$spacer * 3 !important;}

    .p-px-lg-0 {padding-left:0 !important;padding-right:0 !important;}
    .p-px-lg-1 {padding-left:$spacer * 0.25 !important;padding-right:$spacer * 0.25 !important;}
    .p-px-lg-2 {padding-left:$spacer * 0.5 !important;padding-right:$spacer * 0.5 !important;}
    .p-px-lg-3 {padding-left:$spacer * 1 !important;padding-right:$spacer * 1 !important;}
    .p-px-lg-4 {padding-left:$spacer * 1.5 !important;padding-right:$spacer * 1.5 !important;}
    .p-px-lg-5 {padding-left:$spacer * 2 !important;padding-right:$spacer * 2 !important;}
    .p-px-lg-6 {padding-left:$spacer * 3 !important;padding-right:$spacer * 3 !important;}

    .p-py-lg-0 {padding-top:0 !important;padding-bottom:0 !important;}
    .p-py-lg-1 {padding-top:$spacer * 0.25 !important;padding-bottom:$spacer * 0.25 !important;}
    .p-py-lg-2 {padding-top:$spacer * 0.5 !important;padding-bottom:$spacer * 0.5 !important;}
    .p-py-lg-3 {padding-top:$spacer * 1 !important;padding-bottom:$spacer * 1 !important;}
    .p-py-lg-4 {padding-top:$spacer * 1.5 !important;padding-bottom:$spacer * 1.5 !important;}
    .p-py-lg-5 {padding-top:$spacer * 2 !important;padding-bottom:$spacer * 2 !important;}
    .p-py-lg-6 {padding-top:$spacer * 3 !important;padding-bottom:$spacer * 3 !important;}

    .p-p-lg-0 {padding:0 !important;}
    .p-p-lg-1 {padding:$spacer * 0.25 !important;}
    .p-p-lg-2 {padding:$spacer * 0.5 !important;}
    .p-p-lg-3 {padding:$spacer * 1 !important;}
    .p-p-lg-4 {padding:$spacer * 1.5 !important;}
    .p-p-lg-5 {padding:$spacer * 2 !important;}
    .p-p-lg-6 {padding:$spacer * 3 !important;}
}

@media screen and (min-width: $xl) {
    .p-pt-xl-0 {padding-top:0 !important;}
    .p-pt-xl-1 {padding-top:$spacer * 0.25 !important;}
    .p-pt-xl-2 {padding-top:$spacer * 0.5 !important;}
    .p-pt-xl-3 {padding-top:$spacer * 1 !important;}
    .p-pt-xl-4 {padding-top:$spacer * 1.5 !important;}
    .p-pt-xl-5 {padding-top:$spacer * 2 !important;}
    .p-pt-xl-6 {padding-top:$spacer * 3 !important;}

    .p-pr-xl-0 {padding-right:0 !important;}
    .p-pr-xl-1 {padding-right:$spacer * 0.25 !important;}
    .p-pr-xl-2 {padding-right:$spacer * 0.5 !important;}
    .p-pr-xl-3 {padding-right:$spacer * 1 !important;}
    .p-pr-xl-4 {padding-right:$spacer * 1.5 !important;}
    .p-pr-xl-5 {padding-right:$spacer * 2 !important;}
    .p-pr-xl-6 {padding-right:$spacer * 3 !important;}

    .p-pl-xl-0 {padding-left:0 !important;}
    .p-pl-xl-1 {padding-left:$spacer * 0.25 !important;}
    .p-pl-xl-2 {padding-left:$spacer * 0.5 !important;}
    .p-pl-xl-3 {padding-left:$spacer * 1 !important;}
    .p-pl-xl-4 {padding-left:$spacer * 1.5 !important;}
    .p-pl-xl-5 {padding-left:$spacer * 2 !important;}
    .p-pl-xl-6 {padding-left:$spacer * 3 !important;}

    .p-pb-xl-0 {padding-bottom:0 !important;}
    .p-pb-xl-1 {padding-bottom:$spacer * 0.25 !important;}
    .p-pb-xl-2 {padding-bottom:$spacer * 0.5 !important;}
    .p-pb-xl-3 {padding-bottom:$spacer * 1 !important;}
    .p-pb-xl-4 {padding-bottom:$spacer * 1.5 !important;}
    .p-pb-xl-5 {padding-bottom:$spacer * 2 !important;}
    .p-pb-xl-6 {padding-bottom:$spacer * 3 !important;}

    .p-px-xl-0 {padding-left:0 !important;padding-right:0 !important;}
    .p-px-xl-1 {padding-left:$spacer * 0.25 !important;padding-right:$spacer * 0.25 !important;}
    .p-px-xl-2 {padding-left:$spacer * 0.5 !important;padding-right:$spacer * 0.5 !important;}
    .p-px-xl-3 {padding-left:$spacer * 1 !important;padding-right:$spacer * 1 !important;}
    .p-px-xl-4 {padding-left:$spacer * 1.5 !important;padding-right:$spacer * 1.5 !important;}
    .p-px-xl-5 {padding-left:$spacer * 2 !important;padding-right:$spacer * 2 !important;}
    .p-px-xl-6 {padding-left:$spacer * 3 !important;padding-right:$spacer * 3 !important;}

    .p-py-xl-0 {padding-top:0 !important;padding-bottom:0 !important;}
    .p-py-xl-1 {padding-top:$spacer * 0.25 !important;padding-bottom:$spacer * 0.25 !important;}
    .p-py-xl-2 {padding-top:$spacer * 0.5 !important;padding-bottom:$spacer * 0.5 !important;}
    .p-py-xl-3 {padding-top:$spacer * 1 !important;padding-bottom:$spacer * 1 !important;}
    .p-py-xl-4 {padding-top:$spacer * 1.5 !important;padding-bottom:$spacer * 1.5 !important;}
    .p-py-xl-5 {padding-top:$spacer * 2 !important;padding-bottom:$spacer * 2 !important;}
    .p-py-xl-6 {padding-top:$spacer * 3 !important;padding-bottom:$spacer * 3 !important;}

    .p-p-xl-0 {padding:0 !important;}
    .p-p-xl-1 {padding:$spacer * 0.25 !important;}
    .p-p-xl-2 {padding:$spacer * 0.5 !important;}
    .p-p-xl-3 {padding:$spacer * 1 !important;}
    .p-p-xl-4 {padding:$spacer * 1.5 !important;}
    .p-p-xl-5 {padding:$spacer * 2 !important;}
    .p-p-xl-6 {padding:$spacer * 3 !important;}
}
