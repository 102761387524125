@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #5f666c;
  -webkit-font-smoothing: antialiased !important;
}

a,
button {
  margin-right: 0.25em;
}

.fl {
  float: left;
}

.flex {
  display: flex;
}

.flex.content {
  height: 52px;
  line-height: 52px;
  border-bottom: 1px solid #e5e5e5;
}

/* Responsive */
tbody > tr > td .column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .p-table-responsive .p-table-thead > tr > th,
  .p-table-responsive .p-table-tfoot > tr > td {
    display: none !important;
  }

  .p-table-responsive .p-table-tbody > tr > td {
    text-align: left;
    display: block;
    border: 0 none;
    width: 100% !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    clear: left;
  }

  tbody > tr > td .column-title {
    padding: 0.4em;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: bold;
  }
}

.p-datatable-table .p-datatable-tbody > tr.p-highlight {
  background-color: #ff5722 !important;
  border-color: #ff4409;
}

body .p-button.p-state-default.p-button-secondary,
body .p-buttonset.p-button-secondary > .p-button.p-state-default {
  color: #333333;
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
}
body .p-button.p-state-default.p-button-secondary:enabled:hover,
body
  .p-buttonset.p-button-secondary
  > .p-button.p-state-default:enabled:hover {
  background-color: #c8c8c8;
  color: #333333;
  border-color: #c8c8c8;
}
body .p-button.p-state-default.p-button-secondary:enabled:focus,
body
  .p-buttonset.p-button-secondary
  > .p-button.p-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.2em #8dcdff;
  -moz-box-shadow: 0 0 0 0.2em #8dcdff;
  box-shadow: 0 0 0 0.2em #8dcdff;
}

body .p-button.p-state-default.p-button-secondary:enabled:active,
body
.p-buttonset.p-button-secondary
> .p-button.p-state-default:enabled:active {
  background-color: #a0a0a0;
  color: #333333;
  border-color: #a0a0a0;
}

body .p-button.p-state-default.p-button-warning,
body .p-buttonset.p-button-warning > .p-button.p-state-default {
  color: #333333;
  background-color: #ffba01;
  border: 1px solid #ffba01;
}

body .p-button.p-state-default.p-button-warning:enabled:hover,
body
  .p-buttonset.p-button-warning
  > .p-button.p-state-default:enabled:hover {
  background-color: #ed990b;
  color: #333333;
  border-color: #ed990b;
}

body .p-button.p-button-raised {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
}

ripple-animate {
  -webkit-animation: ripple 0.65s linear;
  -moz-animation: ripple 0.65s linear;
  -ms-animation: ripple 0.65s linear;
  -o-animation: ripple 0.65s linear;
  animation: ripple 0.65s linear;
}

body .p-widget-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: inherit;
}

.icon-button {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  outline: none !important;
}

.icon-button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.p-field {
  padding: 0.5rem !important;
  margin-bottom: 0px !important;
}

body .p-button.p-button-icon-only {
  width: 2.357em;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  background-color: #a0a0a0;
  color: #ffffff;
  border-color: #a0a0a0;
  text-decoration: none;
}

#botao--cancelar:hover, #botao--cancelar:focus {
  background-color: #565656;
  border-color: #565656;
}

